@import "../../config.scss";

.loading-overlay {
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: $color_background;

    &__spinner {
        border: 0.75rem solid $color_background_light;
        border-radius: 50%;
        border-top: 0.75rem solid $color_accent;
        width: 5rem;
        height: 5rem;
        animation: spin 2s linear infinite;

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
    }

    &__text {
        margin-top: 3rem;
        color: $color_text_primary;
        font-size: 1.2rem;
        text-align: center;
    }
}