@import "../../config.scss";

$input_distance: 1rem;

.size-input-page {
    &__container {
        h1 {
            color: $color_text_primary;        
            margin-bottom: 3rem;
        }
    }
}

.size-input-margin-container {
    width: 100%;
    padding: 0 $input_distance $input_distance 0;
    overflow: hidden;
    box-sizing: border-box;
}

.size-input-confirm {
    background-color: #00f;
    color: #fff;
    padding: 0.75rem 0;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    &:hover {
        background-color: #00a;
    }
}

.size-input-switch {
    background-color: skyblue;
    color: #000;
    padding: 0.35rem 0;
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    &:hover {
        background-color: darken(skyblue, 10%);
    }
}

.size-input-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
}