@import "./config.scss";

body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100vw;
    height: 100vh;
}

#root {
    height: 100%;
    width: 100%;
    background-color: $color_background;
}

.height-spacer {
    height: 0.5rem;

    &--large {
        height: 1rem;
    }
    &--extra-large {
        height: 2rem;
    }
}

.responsive-container {
    &--h-spacing {
        @media (max-aspect-ratio: "1/1") {
            width: 90%;
        }    
        @media (min-aspect-ratio: "1/1") {
            width: 70%;
        }        
        @media (min-aspect-ratio: "4/3") {
            width: 50%;
        } 
    }
}

.toolbar {
    padding: 1rem 1rem 0 0;
}

.flex-column {
    display: flex;
    align-items: stretch;
    flex-direction: column;

    &--fill {
        width: 100%;
        height: 100%;
    }
    &--center {
        align-items: center;
    }
    &--center-center {
        align-items: center;
        justify-content: center;
    }
}

.flex-row {
    display: flex;
    align-items: stretch;
    flex-direction: row;

    &--wrap {
        flex-wrap: wrap;
    }
    &--center-center {
        align-items: center;
        justify-content: center;
    }
}

.flex-spacer {
    flex: 1;
}