@import "../../config.scss";

.value-with-unit {
    display: flex;
    flex-direction: row;    
    min-width: 12rem;

    box-sizing: border-box;
    border-radius: $size_border_rounding;
    overflow: hidden;

    $input_height: 4rem;
    $input_padding: 1rem;

    &--invalid {
        border-color: #f00;
    }

    &__input {
        display: block;
        border: none;
        outline: none;
        height: $input_height;
        line-height: $input_height;
        padding: 0 $input_padding;
        flex: 1;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.4rem;
        min-width: 5rem;
        color: $color_background;
        background-color: $color_background_light;


        // hide arrows
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;
    }

    &__unit {
        height: $input_height;
        line-height: $input_height;
        padding: 0 $input_padding;
        font-size: 1.4rem;
        background-color: $color_background_light;

    }
}