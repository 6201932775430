@import "../../config.scss";

.pose-scanning-page {
    &__infotext {
        margin: 2rem 0 2rem 0;
        font-size: 1.6rem;
        color: $color_text_primary;
        text-align: center;
    }

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;

        > * {
            position: absolute;
            top: 0;
            left: 0;
        }    
    }
}