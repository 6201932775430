@import "../../config.scss";

.status-bar {
    height: 2rem;
    background-color: $color_background_light;
    width: 100%;
    border-radius: $size_border_rounding;
    overflow: hidden;

    &__inner {
        background-color: $color_accent;
        height: 100%;
    }
}