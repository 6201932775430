.camera-feed {
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}