@import "../../config.scss";

.result-page {
    display: flex;
    align-items: center;
    flex-direction: row;

    &__inner {
        width: 100%;
    }

    &__table {
        width: 100%;

        tr {
            td {
                vertical-align: top;
                padding-bottom: 2rem;
                line-height: 1em;
            }

            &:last-child {
                td {
                    padding-bottom: 0;
                }
            }

            $space_between: 1.5rem;

            td:first-child {
                padding-top: 0.35rem;
                text-align: right;
                width: 50%;
                padding-right: $space_between / 2;
                color: $color_text_secondary;
                text-transform: uppercase;
            }

            td:last-child {
                text-align: left;
                width: 50%;
                font-size: 2.5rem;
                padding-left: $space_between/2;
                color: $color_text_primary;
            }
        }
    }

    &__checkmark-icon {
        display: block;
        width: 8rem;

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            margin-bottom: 4rem;
        }
    }
}