@import "../../config.scss";

.pose-display {
    height: 100%;
    width: 100%;
    overflow: hidden;

    background-color: transparentize($color: $color_background, $amount: 0.1);

    &__inner {
        height: 80%;
        width: 90%;
    }
}