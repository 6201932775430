@import "../../config.scss";

.switch-unit-button {
    background-color: $color_text_secondary;
    color: $color_background;
    padding: 0.5rem 0;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    transition: background-color 0.2s;
    border-radius: $size_border_rounding;

    &:hover {
        background-color: darken($color_text_secondary, 10%);
    }
}