@import "../../config.scss";

.action-button {
    background-color: $color_accent;
    color: $color_text_primary;
    padding: 0.75rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    color: $color_text_primary;

    transition: background-color 0.2s;
    border-radius: $size_border_rounding;

    &:hover {
        background-color: darken($color_accent, 10%);
    }
}